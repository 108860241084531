import { PROJECT_PREFIX } from 'bp-framework/dist/storage/storage.const';
import { IEnvConfigBackOffice } from 'bp-framework/dist/configuration/configuration.interface';
import { IEnvApiOnBetPlatform } from 'bp-framework/dist/env-specific/betplatform/configuration/configuration.interface';

export const BASE_URLS_BET_PLATFORM = {
  default: 'https://bp.1x2bet.bet'
};

export const BASE_URLS_BET_PLATFORM_PROD = {
  default: 'https://bp.app.tasp13.com'
};

export const API_CONFIG_BETPLATFORM: IEnvApiOnBetPlatform = {
  provider: 'betplatform',
  baseUrls: {
    office: BASE_URLS_BET_PLATFORM.default,
    casino: BASE_URLS_BET_PLATFORM.default,
    data: BASE_URLS_BET_PLATFORM.default,
    feed: ''
  },
  coreAuthEndpoints: {
    playerLogin: 'auth/login',
    getProfile: 'player/profile',
    adminLogin: ''
  }
};

export const API_CONFIG_BETPLATFORM_PROD: IEnvApiOnBetPlatform = {
  provider: 'betplatform',
  baseUrls: {
    office: BASE_URLS_BET_PLATFORM_PROD.default,
    casino: BASE_URLS_BET_PLATFORM_PROD.default,
    data: BASE_URLS_BET_PLATFORM_PROD.default,
    feed: ''
  },
  coreAuthEndpoints: {
    playerLogin: 'auth/login',
    getProfile: 'player/profile',
    adminLogin: ''
  }
};

export const PROJECT_CONFIG_BETPLATFORM_DEV: IEnvConfigBackOffice<IEnvApiOnBetPlatform> = {
  production: false,
  environmentName: 'dev',
  projectName: 'bp-backoffice-frontend',
  projectPrefix: PROJECT_PREFIX.backOfficeApp,
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_BET_PLATFORM.default],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_BET_PLATFORM.default}/auth/login`],
    skip401HandlingForTheseUrls: []
  },
  api: API_CONFIG_BETPLATFORM,
  features: {
    storeCredentials: true
  }
};

export const PROJECT_CONFIG_BETPLATFORM_PROD: IEnvConfigBackOffice<IEnvApiOnBetPlatform> = {
  production: true,
  environmentName: 'prod',
  projectName: 'bp-backoffice-frontend',
  projectPrefix: PROJECT_PREFIX.backOfficeApp,
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_BET_PLATFORM_PROD.default],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_BET_PLATFORM_PROD.default}/auth/login`],
    skip401HandlingForTheseUrls: []
  },
  api: API_CONFIG_BETPLATFORM_PROD,
  features: {
    // TODO: Check when we really need these
    storeCredentials: true
  }
};
